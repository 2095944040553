import ctx from '../../../plugins/axios'; 
// eslint-disable-next-line import/no-anonymous-default-export
export default async (queryparams) => {
  try {
      const { data, status } = await ctx.get(`/archiveentries?pageNumber=${queryparams.pageNumber}&deliverySource=${queryparams.deliverySource}&suppName=${queryparams.suppName}&delSrcType=${queryparams.delSrcType}&del_channel=${queryparams.del_channel}&platform=${queryparams.platform}&contactType=${queryparams.contactType}&status=${queryparams.status}&engClass=${queryparams.engClass}&metLabel=${queryparams.metLabel}&metdef=${queryparams.metdef}&hierClass=${queryparams.hierClass}&vehicle=${queryparams.vehicle}`);
      return { data, status };
    }
    catch (error) {
    return { error };
  }
};